//** deal stage Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getDealStage = createAsyncThunk('dealStageSlice/getDealStage', async () => {
    try {
        let response = await axios.get('/deal-stage', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDealStageOption = createAsyncThunk('dealStageSlice/getDealStageOption', async () => {
    try {
        let response = await axios.get('/deal-stage/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createDealStage = createAsyncThunk('dealStageSlice/createDealStage', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/deal-stage', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Deal Stage added successfully.');
        dispatch(getDealStage());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDealStage = createAsyncThunk('dealStageSlice/updateDealStage', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/deal-stage/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Deal Stage updated successfully.');
        await dispatch(getDealStage());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDealStageStatus = createAsyncThunk('dealStageSlice/updateDealStageStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/deal-stage/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getDealStage());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteDealStage = createAsyncThunk('dealStageSlice/deleteDealStage', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/deal-stage/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Deal Stage deleted successfully.');
        await dispatch(getDealStage());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const dealStageSlice = createSlice({
    name: 'dealStageSlice',
    initialState: {
        data: [],
        options: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDealStage.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getDealStageOption.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
    }
});

export default dealStageSlice.reducer;
