//** contact Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';
import showLoader from '@components/loader';

export const getContact = createAsyncThunk('contactSlice/getContact', async () => {
    try {
        let response = await axios.get('/contact', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getContactById = createAsyncThunk('contactSlice/getContactById', async (data) => {
    try {
        let response = await axios.get('/contact/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getContactOption = createAsyncThunk('contactSlice/getContactOption', async () => {
    try {
        let response = await axios.get('/contact/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);

        toast.error(err?.response?.data?.message || 'Something went wrong!');

        return Promise.reject(err);
    }
});

export const createContact = createAsyncThunk('contactSlice/createContact', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/contact', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Contact added successfully.');
        dispatch(getContactByFilter());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateContact = createAsyncThunk('contactSlice/updateContact', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/contact/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Contact updated successfully.');
        await dispatch(getContactByFilter());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateContactStatus = createAsyncThunk('contactSlice/updateContactStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/contact/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Contact Status updated successfully !');
        dispatch(getContactByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteContact = createAsyncThunk('contactSlice/deleteContact', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/contact/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Contact deleted successfully.');
        await dispatch(getContactByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getContactNoteById = createAsyncThunk('contactSlice/getContactNoteById', async (data) => {
    try {
        let response = await axios.get('/contact-note/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getContactAttachmentById = createAsyncThunk('contactSlice/getContactAttachmentById', async (data) => {
    try {
        let response = await axios.get('/contact-attachment/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getContactTodoById = createAsyncThunk('contactSlice/getContactTodoById', async (data) => {
    try {
        let response = await axios.get('/contact/todo/' + data?.id + `?status=${data?.status}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getContactCallById = createAsyncThunk('contactSlice/getContactCallById', async (data) => {
    try {
        let response = await axios.get('/contact/schedule-calls/' + data?.id + `?status=${data?.status}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getContactMeetingById = createAsyncThunk('contactSlice/getContactMeetingById', async (data) => {
    try {
        let response = await axios.get('/contact/meetings/' + data?.id + `?status=${data?.status}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createNote = createAsyncThunk('contactSlice/createNote', async (data) => {
    try {
        let response = await axios.post('/note', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createAttachment = createAsyncThunk('contactSlice/createAttachment', async (data) => {
    try {
        let response = await axios.post('/attachment', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createContactTodo = createAsyncThunk('contactSlice/createContactTodo', async (data) => {
    try {
        let response = await axios.post('/todo', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateNote = createAsyncThunk('contactSlice/updateNote', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/note/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateContactTodo = createAsyncThunk('contactSlice/updateContactTodo', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/todo/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateAttachment = createAsyncThunk('contactSlice/updateAttachment', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/attachment/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteContactNote = createAsyncThunk('contactSlice/deleteContactNote', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/note/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteContactAttachment = createAsyncThunk('contactSlice/deleteContactAttachment', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/attachment/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteContactTodo = createAsyncThunk('contactSlice/deleteContactTodo', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/todo/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
// export const deleteContactCall = createAsyncThunk('contactSlice/deleteContactCall', async (deleteId, { dispatch }) => {
//     try {
//         let response = await axios.delete('/contact/schedule-calls/' + deleteId, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         return Promise.resolve(response);
//     } catch (err) {
//         toast.error(err?.response?.data?.message || 'Something went wrong!');
//         console.log('err', err);
//         return Promise.reject(err);
//     }
// });
export const getContactTimeline = createAsyncThunk('contactSlice/getContactTimeline', async (id, { dispatch }) => {
    try {
        let response = await axios.get('/contact-timeline/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

//account field-filter options
export const getContactFilterOption = createAsyncThunk('leadSourceSlice/getContactFilterOption', async () => {
    try {
        let response = await axios.get('/contact-filter/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getContactByFilter = createAsyncThunk('leadSlice/getContactByFilter', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/contact-filter', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createCampaignContact = createAsyncThunk('contactSlice/createCampaignContact', async (data) => {
    try {
        let response = await axios.post('/contact/campaign', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCampaignById = createAsyncThunk('contactSlice/getCampaignById', async (id) => {
    try {
        let response = await axios.get('/contact/campaign/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const deleteCampaignContact = createAsyncThunk('leadProfileSlice/deleteCampaignContact', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/contact/campaign/' + deleteId, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteBulkContact = createAsyncThunk('contactSlice/deleteBulkContact', async (deleteIds, { dispatch }) => {
    try {
        let response = await axios.put('/contact-bulk-delete', deleteIds, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Contact deleted successfully.');
        await dispatch(getContactByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const ContactBulkImport = createAsyncThunk('contactSlice/ContactBulkImport', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/contact-bulk-create', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Bulk Import successfully !');
        dispatch(getContactByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const ContactBulkUpdate = createAsyncThunk('contactSlice/ContactBulkUpdate', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/contact-bulk-update', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Bulk updated successfully !');
        dispatch(getContactByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getContactFieldOption = createAsyncThunk('contactSlice/getContactFieldOption', async () => {
    try {
        let response = await axios.get('/contact-field/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getContactCustomField = createAsyncThunk('contactSlice/getContactCustomField', async () => {
    try {
        let response = await axios.get('/contact-custom-field', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const createEmailContact = createAsyncThunk('contactSlice/createEmailContact', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/contact/email', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Email Sent successfully');
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getContactEmailsSendById = createAsyncThunk('contactSlice/getContactEmailsSendById', async (data) => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.post('/contact/emails', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Draft
export const getContactEmailsDraftById = createAsyncThunk('contactSlice/getContactEmailsDraftById', async (data) => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.post('/contact/emails', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Schedule
export const getContactEmailsScheduleById = createAsyncThunk('contactSlice/getContactEmailsScheduleById', async (data) => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.post('/contact/emails', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getContactEmailViewById = createAsyncThunk('contactSlice/getContactEmailViewById', async (id) => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.get('/emails/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const contactSlice = createSlice({
    name: 'contactSlice',
    initialState: {
        data: [],
        selectedContact: [],
        options: [],
        note: [],
        attachment: [],
        todo: [],
        calls: [],
        contactFilterOptions: [],
        contactFilter: [],
        meeting: [],
        contactCustomField: [],
        campaign: [],
        contactFieldOptions: [],
        contactEmailsSend: [],
        contactEmailDraft: [],
        contactEmailSchedule: []
    },
    reducers: {
        removeSelectedContact: (state, action) => {
            state.selectedContact = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getContact.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getContactOption.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
        builder.addCase(getContactById.fulfilled, (state, action) => {
            state.selectedContact = action.payload;
            return;
        });
        builder.addCase(getContactNoteById.fulfilled, (state, action) => {
            state.note = action.payload;
            return;
        });
        builder.addCase(getContactAttachmentById.fulfilled, (state, action) => {
            state.attachment = action.payload;
            return;
        });
        builder.addCase(getContactTodoById.fulfilled, (state, action) => {
            state.todo = action.payload;
            return;
        });
        builder.addCase(getContactCallById.fulfilled, (state, action) => {
            state.calls = action.payload;
            return;
        });
        builder.addCase(getContactFilterOption.fulfilled, (state, action) => {
            state.contactFilterOptions = action.payload;
            return;
        });
        builder.addCase(getContactByFilter.fulfilled, (state, action) => {
            state.contactFilter = action.payload;
            return;
        });
        builder.addCase(getContactMeetingById.fulfilled, (state, action) => {
            state.meeting = action.payload;
            return;
        });
        builder.addCase(getCampaignById.fulfilled, (state, action) => {
            state.campaign = action.payload;
            return;
        });
        builder.addCase(getContactFieldOption.fulfilled, (state, action) => {
            state.contactFieldOptions = action.payload;
            return;
        });
        builder.addCase(getContactCustomField.fulfilled, (state, action) => {
            state.contactCustomField = action.payload;
            return;
        });
        builder.addCase(getContactEmailsSendById.fulfilled, (state, action) => {
            state.contactEmailsSend = action.payload;
            return;
        });
        builder.addCase(getContactEmailsDraftById.fulfilled, (state, action) => {
            state.contactEmailDraft = action.payload;
            return;
        });
        builder.addCase(getContactEmailsScheduleById.fulfilled, (state, action) => {
            state.contactEmailSchedule = action.payload;
            return;
        });
    }
});

export const { removeSelectedContact } = contactSlice.actions;
export default contactSlice.reducer;
