// ** Product Api
import showLoader from '@components/loader';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';
// Lead Report
export const getLeadReport = createAsyncThunk('reportSlice/getLeadReport', async (data) => {
    try {
        let response = await axios.post('/reports/lead', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Employee Report
export const getEmployeeReport = createAsyncThunk('reportSlice/getEmployeeReport', async (data) => {
    try {
        showLoader(true);
        let response = await axios.post('/reports/employee-report', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        showLoader(false);
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// Call Report
export const getCallsReport = createAsyncThunk('reportSlice/getCallsReport', async (data) => {
    try {
        let response = await axios.post('/reports/call', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
// Meetings Report
export const getMeetingsReport = createAsyncThunk('reportSlice/getMeetingsReport', async (data) => {
    try {
        let response = await axios.post('/reports/meeting', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getContactReport = createAsyncThunk('reportSlice/getContactReport', async (data) => {
    try {
        let response = await axios.post('/reports/contact', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDealReport = createAsyncThunk('reportSlice/getDealReport', async (data) => {
    try {
        let response = await axios.post('/reports/deal', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCustomerReport = createAsyncThunk('reportSlice/getCustomerReport', async (data) => {
    try {
        let response = await axios.post('/reports/account', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getToDosReport = createAsyncThunk('reportSlice/getToDosReport', async (data) => {
    try {
        let response = await axios.post('/reports/todo', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getConversionReport = createAsyncThunk('reportSlice/getConversionReport', async (data) => {
    try {
        showLoader(true);
        let response = await axios.post('/reports/lead-performance-report', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        showLoader(false);
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
//comapare report api sending date and getting count for comparing
export const getCompareReport = createAsyncThunk('reportSlice/getCompareReport', async (data) => {
    try {
        showLoader(true);
        const response = await axios.post('reports/compare-report', data, {
            headers: { 'Content-Type': 'application/json' }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        showLoader(false);
        console.error('Error fetching compare report:', err);
        return Promise.reject(err);
    }
});

//Login Log Report
export const postLoginLogReport = createAsyncThunk('reportSlice/postLoginLogReport', async (data) => {
    try {
        showLoader(true);
        const response = await axios.post('/reports/login-log', data, {
            headers: { 'Content-Type': 'application/json' }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        showLoader(false);
        console.error('Error fetching Login Log report:', err);
        return Promise.reject(err);
    }
});

//Activity Log Report
export const postActivityLogReport = createAsyncThunk('reportSlice/postActivityLogReport', async (data) => {
    try {
        showLoader(true);
        const response = await axios.post('/reports/action-log', data, {
            headers: { 'Content-Type': 'application/json' }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        showLoader(false);
        console.error('Error fetching Activity Log report:', err);
        return Promise.reject(err);
    }
});

export const reportSlice = createSlice({
    name: 'reportSlice',
    initialState: {
        lead: [],
        employeeReport: [],
        contact: [],
        calls: [],
        conversion: [],
        deal: [],
        meetings: [],
        customer: [],
        toDos: [],
        compareReport: [],
        loginLogReport: [],
        activityLogReport: []
    },
    reducers: {
        removeEmployeeLead: (state, action) => {
            state.employeeReport = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getLeadReport.fulfilled, (state, action) => {
            state.lead = action.payload;
            return;
        });
        builder.addCase(getEmployeeReport.fulfilled, (state, action) => {
            state.employeeReport = action.payload;
            return;
        });
        builder.addCase(getContactReport.fulfilled, (state, action) => {
            state.contact = action.payload;
            return;
        });
        builder.addCase(getCallsReport.fulfilled, (state, action) => {
            state.calls = action.payload;
            return;
        });
        builder.addCase(getDealReport.fulfilled, (state, action) => {
            state.deal = action.payload;
            return;
        });
        builder.addCase(getMeetingsReport.fulfilled, (state, action) => {
            state.meetings = action.payload;
        });
        builder.addCase(getCustomerReport.fulfilled, (state, action) => {
            state.customer = action.payload;
            return;
        });
        builder.addCase(getToDosReport.fulfilled, (state, action) => {
            state.toDos = action.payload;
            return;
        });
        builder.addCase(getConversionReport.fulfilled, (state, action) => {
            state.conversion = action.payload;
            return;
        });
        builder.addCase(getCompareReport.fulfilled, (state, action) => {
            state.compareReport = action.payload || [];
            return;
        });
        builder.addCase(postLoginLogReport.fulfilled, (state, action) => {
            state.loginLogReport = action.payload || [];
            return;
        });
        builder.addCase(postActivityLogReport.fulfilled, (state, action) => {
            state.activityLogReport = action.payload || [];
            return;
        });
    }
});

export const { removeEmployeeLead } = reportSlice.actions;

export default reportSlice.reducer;
