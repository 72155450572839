//** Lead Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports

import { toast } from 'react-toastify';
import showLoader from '@components/loader';
import axios from '@src/service/axios';
// import themeConfig from '../../../configs/themeConfig';
export const addAccount = createAsyncThunk('meta/addAccount', async (data) => {
    try {
        let response = await axios.get(`meta/ad-accounts`, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: data.data
            }
        });
        return response?.data?.data;
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        throw err;
    }
});

export const addPages = createAsyncThunk('meta/addPages', async (data) => {
    try {
        let response = await axios.get(`meta/pages`, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: data.data
            }
        });
        return response?.data?.data;
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        throw err;
    }
});

export const addForm = createAsyncThunk('meta/addForm', async (data) => {
    try {
        showLoader(true);
        let response = await axios.get(`/meta/forms?pageId=${data.pageId}`, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: data.pageToken
            }
        });
        showLoader(false);
        return response?.data?.data;
    } catch (err) {
        showLoader(false);
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        throw err;
    }
});

export const getAccessToken = createAsyncThunk('meta/getAccessToken', async (data) => {
    try {
        let response = await axios.get(`/meta/user/extend-token-expiration`, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: data
            }
        });
        return response?.data?.data;
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        throw err;
    }
});

export const getMetaConnections = createAsyncThunk('meta/getMetaConnections', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/meta', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        showLoader(false);
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getMetaAccountPage = createAsyncThunk('meta/getMetaAccountPage', async (id) => {
    try {
        showLoader(true);
        let response = await axios.get(`/meta/account-page/${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        showLoader(false);
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createUserAccessToken = createAsyncThunk('meta/createUserAccessToken', async (data) => {
    try {
        let response = await axios.post('/meta/user/access-token', data?.body, {
            headers: {
                'Content-Type': 'application/json',
                accessToken: data.accessToken
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        return Promise.reject(err);
    }
});

//datagrid API
export const createConnection = createAsyncThunk('meta/createConnection', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/connection', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        dispatch(getConnections());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getConnections = createAsyncThunk('meta/getConnections', async () => {
    try {
        let response = await axios.get('/connection', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateConnection = createAsyncThunk('meta/updateConnection', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/connection/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Connection  updated successfully.');
        dispatch(getConnections());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const updateConnectionStatus = createAsyncThunk('meta/updateConnectionStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/connection/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Connection updated successfully !');
        dispatch(getConnections());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteConnection = createAsyncThunk('meta/deleteConnection', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.delete('/connection/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Connection  deleted successfully.');
        dispatch(getConnections());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

//
export const createSubscribeLead = createAsyncThunk('meta/createSubscribeLead', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/meta/subscribe-lead', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Page subscribed successfully.');
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteMetaConnection = createAsyncThunk('meta/deleteMetaConnection', async (deleteId, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.delete('/meta/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Meta  deleted successfully.');
        dispatch(getMetaConnections());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const metaSlice = createSlice({
    name: 'metaSlice',
    initialState: {
        data: [],
        accounts: [],
        pages: [],
        form: [],
        connection: [],
        account: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getConnections.fulfilled, (state, action) => {
            state.data = action.payload;
        });
        builder.addCase(addAccount.fulfilled, (state, action) => {
            state.accounts = action.payload;
        });
        builder.addCase(addPages.fulfilled, (state, action) => {
            state.pages = action.payload;
        });
        builder.addCase(addForm.fulfilled, (state, action) => {
            state.form = action.payload;
        });
        builder.addCase(getMetaConnections.fulfilled, (state, action) => {
            state.connection = action.payload;
        });
        builder.addCase(getMetaAccountPage.fulfilled, (state, action) => {
            state.account = action.payload;
        });
    }
});

export default metaSlice.reducer;
