//** Lead Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';
import queryString from 'query-string';
export const getNoteById = createAsyncThunk('leadProfileSlice/getNoteById', async (id) => {
    try {
        let response = await axios.get('/note/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getAttachmentById = createAsyncThunk('leadProfileSlice/getAttachmentById', async (id) => {
    try {
        let response = await axios.get('/attachment/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCampaignById = createAsyncThunk('leadProfileSlice/getCampaignById', async (id) => {
    try {
        let response = await axios.get('/lead/campaign/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getTodoById = createAsyncThunk('leadProfileSlice/getTodoById', async (data) => {
    try {
        let response = await axios.get('/lead/todo/' + data?.id + `?status=${data?.status}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCloseTodoById = createAsyncThunk('leadProfileSlice/getCloseTodoById', async (data) => {
    try {
        let response = await axios.get('/lead/todo/' + data?.id + `?status=${data?.status}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLeadMeetingById = createAsyncThunk('leadProfileSlice/getLeadMeetingById', async (data) => {
    try {
        let response = await axios.get('/lead/meetings/' + data?.id + `?status=${data?.status}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLeadCallById = createAsyncThunk('leadProfileSlice/getLeadCallById', async (data) => {
    try {
        let response = await axios.get('/lead/schedule-calls/' + data?.id + `?status=${data?.status}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createNote = createAsyncThunk('leadProfileSlice/createNote', async (data) => {
    try {
        let response = await axios.post('/note', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createEmailLead = createAsyncThunk('leadProfileSlice/createEmailLead', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/lead/email', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Email Sent successfully');
        // dispatch(getLeadEmailsById({ id: data?.id, emailType: '0' }));
        // dispatch(getLeadEmailsById({ id: data?.id, emailType: '1' }));
        // dispatch(getLeadEmailsById({ id: data?.id, emailType: '2' }));
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createAttachment = createAsyncThunk('leadProfileSlice/createAttachment', async (data) => {
    try {
        let response = await axios.post('/attachment', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createCampaignLead = createAsyncThunk('leadProfileSlice/createCampaignLead', async (data) => {
    try {
        let response = await axios.post('/lead/campaign', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateNote = createAsyncThunk('leadProfileSlice/updateNote', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/note/' + data.id, data.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const deleteAttachment = createAsyncThunk('leadProfileSlice/deleteAttachment', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/attachment/' + deleteId, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const deleteCampaignLead = createAsyncThunk('leadProfileSlice/deleteCampaignLead', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/lead/campaign/' + deleteId, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteNote = createAsyncThunk('leadProfileSlice/deleteNote', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/note/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        console.log('err', err);
        return Promise.reject(err);
    }
});
//send
export const getLeadEmailsSendById = createAsyncThunk('leadProfileSlice/getLeadEmailsSendById', async (data) => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.post('/lead/emails', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Draft
export const getLeadEmailsDraftById = createAsyncThunk('leadProfileSlice/getLeadEmailsDraftById', async (data) => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.post('/lead/emails', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Schedule
export const getLeadEmailsScheduleById = createAsyncThunk('leadProfileSlice/getLeadEmailsScheduleById', async (data) => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.post('/lead/emails', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getLeadEmailViewById = createAsyncThunk('leadProfileSlice/getLeadEmailViewById', async (id) => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.get('/emails/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getTemplateOption = createAsyncThunk('leadProfileSlice/getTemplateOption', async (data) => {
    try {
        let response = await axios.post('https://app.11za.in/apis/template/getTemplatesAll', data, {
            headers: {
                'Content-Type': 'application/json'
            }
            // withCredentials: false // Ensure credentials are included if needed
        });
        return Promise.resolve(response?.data?.Data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong while getting templates!');
        return Promise.reject(err);
    }
});

export const createMessageTemplate = createAsyncThunk('leadProfileSlice/createMessageTemplate', async (data) => {
    try {
        let response = await axios.post('/oneoneza', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        toast.success(response?.data?.message || 'Message Sent successfully');
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createMyOperatorScheduleCall = createAsyncThunk(
    'leadProfileSlice/createMyOperatorScheduleCall',
    async (data, { rejectWithValue }) => {
        try {
            let response = await axios.post('/myoperator/schedule-call', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.data?.message || 'Call Sent successfully');
            return Promise.resolve(response?.data?.data);
        } catch (err) {
            console.log('err', err);
            toast.error(err?.response?.data?.error || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

export const getLeadEmailHistoryById = createAsyncThunk('leadProfileSlice/getLeadEmailHistoryById', async (id) => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.get('/emails/history/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const leadProfileSlice = createSlice({
    name: 'leadProfileSlice',
    initialState: {
        note: [],
        attachment: [],
        campaign: [],
        todo: [],
        closeTodo: [],
        meeting: [],
        call: [],
        leadEmailsSend: [],
        leadEmailDraft: [],
        leadEmailSchedule: [],
        templateOption: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getNoteById.fulfilled, (state, action) => {
            state.note = action.payload;
            return;
        });
        builder.addCase(getAttachmentById.fulfilled, (state, action) => {
            state.attachment = action.payload;
            return;
        });
        builder.addCase(getCampaignById.fulfilled, (state, action) => {
            state.campaign = action.payload;
            return;
        });
        builder.addCase(getTodoById.fulfilled, (state, action) => {
            state.todo = action.payload;
            return;
        });
        builder.addCase(getCloseTodoById.fulfilled, (state, action) => {
            state.closeTodo = action.payload;
            return;
        });
        builder.addCase(getLeadMeetingById.fulfilled, (state, action) => {
            state.meeting = action.payload;
            return;
        });
        builder.addCase(getLeadCallById.fulfilled, (state, action) => {
            state.call = action.payload;
            return;
        });
        builder.addCase(getLeadEmailsSendById.fulfilled, (state, action) => {
            state.leadEmailsSend = action.payload;
            return;
        });
        builder.addCase(getLeadEmailsDraftById.fulfilled, (state, action) => {
            state.leadEmailDraft = action.payload;
            return;
        });
        builder.addCase(getLeadEmailsScheduleById.fulfilled, (state, action) => {
            state.leadEmailSchedule = action.payload;
            return;
        });
        builder.addCase(getTemplateOption.fulfilled, (state, action) => {
            state.templateOption = action.payload;
            return;
        });
    }
});

export default leadProfileSlice.reducer;
