//** meeting Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';
import queryString from 'query-string';
import showLoader from '@components/loader';

export const getMeeting = createAsyncThunk('meetingSlice/getMeeting', async (data) => {
    try {
        let response = await axios.get(`/meetings-status?statusData=${data}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getNoteById = createAsyncThunk('meetingSlice/getNoteById', async (data) => {
    try {
        let response = await axios.get('/meeting-note/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAttachmentById = createAsyncThunk('meetingSlice/getAttachmentById', async (data) => {
    try {
        let response = await axios.get('/meeting-attachment/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getMeetingById = createAsyncThunk('meetingSlice/getMeetingById', async (data) => {
    try {
        let response = await axios.get('/meetings/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getMeetingOption = createAsyncThunk('meetingSlice/getMeetingOption', async () => {
    try {
        let response = await axios.get('/meetings/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createScheduleMeeting = createAsyncThunk('meetingSlice/createScheduleMeeting', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/meetings', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        toast.success(response?.data?.message || 'Schedule Meeting created successfully.');
        dispatch(getScheduleMeetingByFilter({ statusData: '0' }));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createLogMeeting = createAsyncThunk('meetingSlice/createLogMeeting', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/meetings', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Log Meeting created successfully.');
        dispatch(getClosedMeetingByFilter({ statusData: '2' }));
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createMeetingAttachment = createAsyncThunk('meetingSlice/createMeetingAttachment', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/attachment', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateMeeting = createAsyncThunk('meetingSlice/updateMeeting', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/meetings/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Meeting updated successfully.');
        dispatch(getScheduleMeetingByFilter({ statusData: '0' }));
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateNoteMeeting = createAsyncThunk('meetingSlice/updateNoteMeeting', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/note/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateMeetingStatus = createAsyncThunk('meetingSlice/updateMeetingStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/meetings/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Meeting Status updated successfully !');
        dispatch(getScheduleMeetingByFilter({ statusData: '0' }));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteMeeting = createAsyncThunk('meetingSlice/deleteMeeting', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/meetings/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Meeting deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteMeetingNote = createAsyncThunk('meetingSlice/deleteMeetingNote', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/note/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteMeetingAttachment = createAsyncThunk('meetingSlice/deleteMeetingAttachment', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/attachment/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

//meetings field-filter options
export const getMeetingFilterOption = createAsyncThunk('meetingSlice/getMeetingFilterOption', async () => {
    try {
        let response = await axios.get('/meeting-filter/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// export const getMeetingByFilter = createAsyncThunk('meetingSlice/getMeetingByFilter', async (data, { dispatch }) => {
//     try {
//         let response = await axios.post('/meeting-filter', data, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         // toast.success(response?.data?.message || 'Lead added successfully.');
//         await dispatch(removeSelectedMeetings());
//         return Promise.resolve(response?.data?.data);
//     } catch (err) {
//         toast.error(err?.response?.data?.message || 'Something went wrong!');
//         console.log('err', err);
//         return Promise.reject(err);
//     }
// });

export const getScheduleMeetingByFilter = createAsyncThunk('meetingSlice/getScheduleMeetingByFilter', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/meeting-filter', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Lead added successfully.');
        await dispatch(removeScheduleMeetings());
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getInProgressMeetingByFilter = createAsyncThunk('meetingSlice/getInProgressMeetingByFilter', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/meeting-filter', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Lead added successfully.');
        await dispatch(removeInProgressMeetings());
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getClosedMeetingByFilter = createAsyncThunk('meetingSlice/getClosedMeetingByFilter', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/meeting-filter', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Lead added successfully.');
        await dispatch(removeClosedMeetings());
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

//for bulk update field
export const getMeetingFieldOption = createAsyncThunk('meetingSlice/getMeetingFieldOption', async () => {
    try {
        let response = await axios.get('/meetings-field/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//lead bulk update
export const meetingBulkUpdate = createAsyncThunk('meetingSlice/meetingBulkUpdate', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/meetings-bulk-update', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Meetings updated successfully !');

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// bulk delete
export const deleteBulkMeeting = createAsyncThunk('meetingSlice/deleteBulkMeeting', async (deleteIds, { dispatch }) => {
    try {
        let response = await axios.put('/meetings-bulk-delete', deleteIds, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Meeting deleted successfully.');

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getMeetingCustomField = createAsyncThunk('meetingSlice/getMeetingCustomField', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/meetings-custom-field?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//** outcome of call */
export const updateOutcomeMeeting = createAsyncThunk('meetingSlice/updateOutcomeCall', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/meetings/add-outcome/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Outcome of Meetings updated successfully.');
        dispatch(getInProgressMeetingByFilter({ statusData: '1' }));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const meetingSlice = createSlice({
    name: 'meetingSlice',
    initialState: {
        data: [],
        selectedMeeting: {},
        options: [],
        meetingFilterOptions: [],
        meetingFilter: [],
        meetingFieldOptions: [],
        note: [],
        attachment: [],
        meetingCustomField: [],
        scheduleMeeting: [],
        inProgressMeeting: [],
        closedMeeting: []
    },
    reducers: {
        removeScheduleMeetings: (state, action) => {
            state.meetingFilter = [];
        },
        removeInProgressMeetings: (state, action) => {
            state.inProgressMeeting = [];
        },
        removeClosedMeetings: (state, action) => {
            state.closedMeeting = [];
        },
        removeSelectedMeetings: (state, action) => {
            state.selectedMeeting = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getMeeting.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getMeetingOption.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
        builder.addCase(getMeetingById.fulfilled, (state, action) => {
            state.selectedMeeting = action.payload;
            return;
        });
        builder.addCase(getMeetingFilterOption.fulfilled, (state, action) => {
            state.meetingFilterOptions = action.payload;
            return;
        });
        // builder.addCase(getMeetingByFilter.fulfilled, (state, action) => {
        //     state.meetingFilter = action.payload;
        //     return;
        // });
        builder.addCase(getMeetingFieldOption.fulfilled, (state, action) => {
            state.meetingFieldOptions = action.payload;
            return;
        });
        builder.addCase(getNoteById.fulfilled, (state, action) => {
            state.note = action.payload;
            return;
        });
        builder.addCase(getAttachmentById.fulfilled, (state, action) => {
            state.attachment = action.payload;
            return;
        });
        builder.addCase(getMeetingCustomField.fulfilled, (state, action) => {
            state.meetingCustomField = action.payload;
            return;
        });
        builder.addCase(getScheduleMeetingByFilter.fulfilled, (state, action) => {
            state.scheduleMeeting = action.payload;
            return;
        });
        builder.addCase(getInProgressMeetingByFilter.fulfilled, (state, action) => {
            state.inProgressMeeting = action.payload;
            return;
        });
        builder.addCase(getClosedMeetingByFilter.fulfilled, (state, action) => {
            state.closedMeeting = action.payload;
            return;
        });
    }
});
export const { removeClosedMeetings, removeInProgressMeetings, removeScheduleMeetings, removeSelectedMeetings } = meetingSlice.actions;

export default meetingSlice.reducer;
