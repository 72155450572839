//** buying-role Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';
import showLoader from '@components/loader';
export const getMenuOrder = createAsyncThunk('menuOrderSlice/getMenuOrder', async () => {
    try {
        let response = await axios.get('/menu-order', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getMenuOrderRoute = createAsyncThunk('menuOrderSlice/getMenuOrderRoute', async () => {
    try {
        let response = await axios.get('/menu-order-route', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('menuOrderSlice/updateStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/menu-order/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully.');
        dispatch(getMenuOrder());
        dispatch(getMenuOrderRoute());
        showLoader(false);
        console.log('response.data', response.data);
        return Promise.resolve(response.data);
    } catch (err) {
        console.log('err', err);
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createMenuOrder = createAsyncThunk('menuOrderSlice/createMenuOrder', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/menu-order', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Menu Order added successfully.');
        dispatch(getMenuOrder());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateMenuOrder = createAsyncThunk('menuOrderSlice/updateMenuOrder', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/menu-order/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Buying Role updated successfully.');
        await dispatch(getMenuOrder());
        await dispatch(getMenuOrderRoute());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteMenuOrder = createAsyncThunk('menuOrderSlice/deleteMenuOrder', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/menu-order/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Menu Order deleted successfully.');
        await dispatch(getMenuOrder());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateLevelMenuOrder = createAsyncThunk('menuOrderSlice/updateLevelMenuOrder', async (data, { dispatch }) => {
    try {
        showLoader(true);

        let response = await axios.put(
            '/menu-order/update/level',
            { menuOrderIds: data },
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        toast.success(response?.data?.message || 'Menu Order Level updated successfully.');
        dispatch(getMenuOrder());
        await dispatch(getMenuOrderRoute());
        showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const menuOrderSlice = createSlice({
    name: 'menuOrderSlice',
    initialState: {
        data: [],
        route: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getMenuOrder.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getMenuOrderRoute.fulfilled, (state, action) => {
            state.route = action.payload;
            return;
        });
    }
});

export default menuOrderSlice.reducer;
