//** lead-source Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getLeadSource = createAsyncThunk('leadSourceSlice/getLeadSource', async () => {
    try {
        let response = await axios.get('/lead-source', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLeadSourceOption = createAsyncThunk('leadSourceSlice/getLeadSourceOption', async () => {
    try {
        let response = await axios.get('/lead-source/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createLeadSource = createAsyncThunk('leadSourceSlice/createLeadSource', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/lead-source', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Lead Source added successfully.');
        dispatch(getLeadSource());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateLeadSource = createAsyncThunk('leadSourceSlice/updateLeadSource', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/lead-source/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Lead Source updated successfully.');
        await dispatch(getLeadSource());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateLeadSourceStatus = createAsyncThunk('leadSourceSlice/updateLeadSourceStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/lead-source/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Lead Source Status updated successfully !');
        dispatch(getLeadSource());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteLeadSource = createAsyncThunk('leadSourceSlice/deleteLeadSource', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/lead-source/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Lead Source deleted successfully.');
        await dispatch(getLeadSource());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const leadSourceSlice = createSlice({
    name: 'leadSourceSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLeadSource.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getLeadSourceOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default leadSourceSlice.reducer;
