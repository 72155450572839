//** Lead Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getVendor = createAsyncThunk('vendorSlice/getVendor', async () => {
    try {
        let response = await axios.get('/vendor', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getVendorOption = createAsyncThunk('vendorSlice/getVendorOption', async () => {
    try {
        let response = await axios.get('/vendor/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getVendorById = createAsyncThunk('vendorSlice/getVendorById', async (data) => {
    try {
        let response = await axios.get('/vendor/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const createVendor = createAsyncThunk('vendorSlice/createVendor', async (data) => {
    try {
        let response = await axios.post('/vendor', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateVendor = createAsyncThunk('leadSlice/updateVendor', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/vendor/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Vendor updated successfully.');
        await dispatch(getVendor());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteVendor = createAsyncThunk('leadSlice/deleteVendor', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/vendor/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Vendor Delete successfully.');
        await dispatch(getVendor());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const vendorSlice = createSlice({
    name: 'vendorSlice',
    initialState: {
        data: [],
        selectedVendor: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVendor.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getVendorById.fulfilled, (state, action) => {
            state.selectedVendor = action.payload;
            return;
        });
        builder.addCase(getVendorOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default vendorSlice.reducer;
