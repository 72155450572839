//** meeting Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';
import queryString from 'query-string';
import showLoader from '@components/loader';

export const getCalls = createAsyncThunk('callsSlice/getCalls', async (data) => {
    try {
        let response = await axios.get(`/schedule-calls?statusData=${data}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCallsById = createAsyncThunk('callsSlice/getCallsById', async (data) => {
    try {
        let response = await axios.get('/schedule-calls/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getNoteCallsById = createAsyncThunk('callsSlice/getNoteCallsById', async (data) => {
    try {
        let response = await axios.get('/schedule-call-note/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAttachmentCallsById = createAsyncThunk('callsSlice/getAttachmentCallsById', async (data) => {
    try {
        let response = await axios.get('/schedule-call-attachment/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getCallsOption = createAsyncThunk('callsSlice/getCallsOption', async () => {
    try {
        let response = await axios.get('/schedule-calls/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);

        toast.error(err?.response?.data?.message || 'Something went wrong!');

        return Promise.reject(err);
    }
});

export const createScheduleCalls = createAsyncThunk('callsSlice/createScheduleCalls', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/schedule-calls', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Schedule Calls created successfully.');
        dispatch(getCalls());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createCallsAttachment = createAsyncThunk('callsSlice/createCallsAttachment', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/attachment', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createCallsNote = createAsyncThunk('callsSlice/createCallsNote', async (data, { dispatch }) => {
    
    try {
        let response = await axios.post('/note', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createLogCalls = createAsyncThunk('callsSlice/createLogCalls', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/schedule-calls', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Log Calls created successfully.');
        dispatch(getCalls());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateCalls = createAsyncThunk('callsSlice/updateCalls', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/schedule-calls/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Calls updated successfully.');
        await dispatch(getCalls());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateNote = createAsyncThunk('callsSlice/updateNote', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/note/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateContactCalls = createAsyncThunk('callsSlice/updateCalls', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/schedule-calls/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Calls updated successfully.');
        await dispatch(getCalls());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateCallsStatus = createAsyncThunk('callsSlice/updateCallsStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/schedule-calls/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Calls Status updated successfully !');
        dispatch(getCalls());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteCalls = createAsyncThunk('callsSlice/deleteCalls', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/schedule-calls/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Calls deleted successfully.');
        await dispatch(getCalls());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// bulk delete
export const deleteBulkCalls = createAsyncThunk('callsSlice/deleteBulkCalls', async (deleteIds, { dispatch }) => {
    try {
        let response = await axios.put('/schedule-calls-bulk-delete', deleteIds, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Calls deleted successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteNote = createAsyncThunk('callsSlice/deleteNote', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/note/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteAttachment = createAsyncThunk('callsSlice/deleteAttachment', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/attachment/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

//call field-filter options
export const getCallFilterOption = createAsyncThunk('leadSourceSlice/getCallFilterOption', async () => {
    try {
        let response = await axios.get('/schedule-calls-filter/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// export const getCallByFilter = createAsyncThunk('leadSlice/getCallByFilter', async (data, { dispatch }) => {
//     try {
//         let response = await axios.post('/schedule-calls-filter', data, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         // toast.success(response?.data?.message || 'Lead added successfully.');
//         return Promise.resolve(response?.data?.data);
//     } catch (err) {
//         toast.error(err?.response?.data?.message || 'Something went wrong!');
//         console.log('err', err);
//         return Promise.reject(err);
//     }
// });
export const getScheduleCallByFilter = createAsyncThunk('callsSlice/getScheduleCallByFilter', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/schedule-calls-filter', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Lead added successfully.');
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getInProgressCallByFilter = createAsyncThunk('callsSlice/getInProgressCallByFilter', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/schedule-calls-filter', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Lead added successfully.');
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getClosedCallByFilter = createAsyncThunk('callsSlice/getClosedCallByFilter', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/schedule-calls-filter', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Lead added successfully.');
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getCallsCustomField = createAsyncThunk('callsSlice/getCallsCustomField', async (data) => {
    const query = queryString.stringify(data);
    try {
        let response = await axios.get('/schedule-calls-custom-field?' + query, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//** outcome of call */
export const updateOutcomeCall = createAsyncThunk('callsSlice/updateOutcomeCall', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/schedule-calls/add-outcome/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Outcome of Calls updated successfully.');
        // await dispatch(getCallByFilter({ statusData: '1' }));
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createConnectToCalls = createAsyncThunk('callsSlice/createConnectToCalls', async (data, { rejectWithValue }) => {
    try {
        let response = await axios.post('/myoperator', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.data?.message || 'Request accepted successfully');
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        // return Promise.reject(err);
        return rejectWithValue(err);
    }
});

//getOutcomeCallById
export const getOutcomeCallById = createAsyncThunk('callsSlice/getOutcomeCallById', async (id) => {
    try {
        let response = await axios.get('/call-outcome/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const callsSlice = createSlice({
    name: 'callsSlice',
    initialState: {
        data: [],
        selectedCall: {},
        options: [],
        callFilterOptions: [],
        callFilter: [],
        note: [],
        attachment: [],
        callsCustomField: [],
        scheduleCall: [],
        inProgressCall: [],
        closedCall: []
    },
    reducers: {
        removeScheduleCalls: (state, action) => {
            state.scheduleCall = [];
        },
        removeInProgressCalls: (state, action) => {
            state.inProgressCall = [];
        },
        removeClosedCalls: (state, action) => {
            state.closedCall = [];
        },
        removeSelectedCalls: (state, action) => {
            state.selectedCall = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getCalls.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getCallsOption.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
        builder.addCase(getCallsById.fulfilled, (state, action) => {
            state.selectedCall = action.payload;
            return;
        });
        builder.addCase(getCallFilterOption.fulfilled, (state, action) => {
            state.callFilterOptions = action.payload;
            return;
        });
        // builder.addCase(getCallByFilter.fulfilled, (state, action) => {
        //     state.callFilter = action.payload;
        //     return;
        // });
        builder.addCase(getNoteCallsById.fulfilled, (state, action) => {
            state.note = action.payload;
            return;
        });
        builder.addCase(getAttachmentCallsById.fulfilled, (state, action) => {
            state.attachment = action.payload;
            return;
        });
        builder.addCase(getCallsCustomField.fulfilled, (state, action) => {
            state.callsCustomField = action.payload;
            return;
        });
        builder.addCase(getScheduleCallByFilter.fulfilled, (state, action) => {
            state.scheduleCall = action.payload;
            return;
        });
        builder.addCase(getInProgressCallByFilter.fulfilled, (state, action) => {
            state.inProgressCall = action.payload;
            return;
        });
        builder.addCase(getClosedCallByFilter.fulfilled, (state, action) => {
            state.closedCall = action.payload;
            return;
        });
    }
});

export const { removeClosedCalls, removeInProgressCalls, removeScheduleCalls, removeSelectedCalls } = callsSlice.actions;

export default callsSlice.reducer;
