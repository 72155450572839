//** deals Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getCalendar = createAsyncThunk('calendarSlice/getCalendar', async () => {
    try {
        let response = await axios.get('/calender', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const calendarSlice = createSlice({
    name: 'calendarSlice',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCalendar.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
    }
});

export default calendarSlice.reducer;
