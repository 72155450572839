// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit';

// ** ThemeConfig
import themeConfig from '../configs/themeConfig';

import SecureLS from 'secure-ls';

export const secureLs = new SecureLS({
    encodingType: 'aes',
    encryptionSecret: 'chpl-crm-admin-panel',
    isCompression: false
});

const initialActiveTab = () => {
    const item = window.localStorage.getItem('activeTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.activeTab;
};
const initialLeadTab = () => {
    const item = window.localStorage.getItem('leadTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.leadTab;
};

const initialAccountTab = () => {
    const item = window.localStorage.getItem('accountTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.accountTab;
};

const initialContactTab = () => {
    const item = window.localStorage.getItem('contactTab');
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : themeConfig.layout.contactTab;
};

const initialToDosTab = () => {
    const item = window.localStorage.getItem('toDosTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.toDosTab;
};

const initialMeetingTab = () => {
    const item = window.localStorage.getItem('meetingTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.meetingTab;
};

const initialDealTab = () => {
    const item = window.localStorage.getItem('dealTab');

    //** Parse stored json or if none return initialValue

    return item ? JSON.parse(item) : themeConfig.layout.dealTab;
};

export const tabSlice = createSlice({
    name: 'tabSliceData',
    initialState: {
        activeTab: initialActiveTab(),
        leadTab: initialLeadTab(),
        toDosTab: initialToDosTab(),
        accountTab: initialAccountTab(),
        contactTab: initialContactTab(),
        meetingTab: initialMeetingTab(),
        dealTab: initialDealTab()
    },
    reducers: {
        handleActiveTab: (state, action) => {
            state.activeTab = action.payload;
            localStorage.setItem('activeTab', JSON.stringify(action.payload));
        },
        removeTabs: (state, action) => {
            state.activeTab = '1';
            localStorage.setItem('activeTab', '1');
        },
        handleLeadTab: (state, action) => {
            state.leadTab = action.payload;
            localStorage.setItem('leadTab', JSON.stringify(action.payload));
        },
        removeLeadTabs: (state, action) => {
            state.leadTab = '1';
            localStorage.setItem('leadTab', '1');
        },
        handleAccountTab: (state, action) => {
            state.accountTab = action.payload;
            localStorage.setItem('accountTab', JSON.stringify(action.payload));
        },
        removeAccountTabs: (state, action) => {
            state.accountTab = '1';
            localStorage.setItem('accountTab', '1');
        },
        handleToDosTab: (state, action) => {
            state.toDosTab = action.payload;
            localStorage.setItem('toDosTab', JSON.stringify(action.payload));
        },
        removeToDosTabs: (state, action) => {
            state.toDosTab = '1';
            localStorage.setItem('toDosTab', '1');
        },
        handleContactTab: (state, action) => {
            state.contactTab = action.payload;
            localStorage.setItem('contactTab', JSON.stringify(action.payload));
        },
        removeContactTabs: (state, action) => {
            state.contactTab = '1';
            localStorage.setItem('contactTab', '1');
        },
        handleMeetingTab: (state, action) => {
            state.meetingTab = action.payload;
            localStorage.setItem('meetingTab', JSON.stringify(action.payload));
        },
        removeMeetingTabs: (state, action) => {
            state.meetingTab = '1';
            localStorage.setItem('meetingTab', '1');
        },
        handleDealTab: (state, action) => {
            state.dealTab = action.payload;
            localStorage.setItem('dealTab', JSON.stringify(action.payload));
        },
        removeDealTabs: (state, action) => {
            state.dealTab = '1';
            localStorage.setItem('dealTab', '1');
        }
    }
});

export const {
    handleActiveTab,
    removeTabs,
    handleLeadTab,
    removeLeadTabs,
    handleToDosTab,
    removeToDosTabs,
    handleAccountTab,
    removeAccountTabs,
    handleContactTab,
    removeContactTabs,
    handleMeetingTab,
    removeMeetingTabs,
    handleDealTab,
    removeDealTabs
} = tabSlice.actions;

export default tabSlice.reducer;
