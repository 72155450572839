//** buying-role Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

//Lead OverView
export const getLeadOverView = createAsyncThunk('dashboardSlice/getLeadOverView', async (id) => {
    try {
        let response = await axios.get('/dashboard/lead-overview?option=' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Upcoming Task
export const getUpcomingTask = createAsyncThunk('dashboardSlice/getUpcomingTask', async (id) => {
    try {
        let response = await axios.get('/dashboard/upcoming-todo?option=' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Upcoming Meetings
export const getUpcomingMeetings = createAsyncThunk('dashboardSlice/getUpcomingMeetings', async (id) => {
    try {
        let response = await axios.get('/dashboard/upcoming-meeting?option=' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Upcoming call
export const getUpcomingCall = createAsyncThunk('dashboardSlice/getUpcomingCall', async (id) => {
    try {
        let response = await axios.get('/dashboard/upcoming-schedule-call?option=' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Leads Statistic
export const getLeadsStatistics = createAsyncThunk('dashboardSlice/getLeadsStatistics', async (id) => {
    try {
        let response = await axios.get('/dashboard/lead-statistics?option=' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Customer Statistics
export const getCustomerStatistics = createAsyncThunk('dashboardSlice/getCustomerStatistics', async (id) => {
    try {
        let response = await axios.get('/dashboard/customer-statistics?option=' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Contact Statistic
export const getContactStatistics = createAsyncThunk('dashboardSlice/getContactStatistics', async (id) => {
    try {
        let response = await axios.get('/dashboard/contact-statistics?option=' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Conversion Rate
export const getConversionRate = createAsyncThunk('dashboardSlice/getConversionLead', async (id) => {
    try {
        let response = await axios.get('/dashboard/lead-conversion-rate?option=' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//Lead Stages
export const getLeadStages = createAsyncThunk('dashboardSlice/getLeadStages', async (id) => {
    try {
        let response = await axios.get('/dashboard/lead-status-percentage?option=' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState: {
        leadOverView: [],
        upcomingTask: [],
        leadStatistics: [],
        customerStatistics: [],
        contactStatistics: [],
        upcomingMeetings: [],
        upcomingCall: [],
        conversionRate: {},
        leadStages: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLeadOverView.fulfilled, (state, action) => {
            state.leadOverView = action.payload;
            return;
        });
        builder.addCase(getUpcomingTask.fulfilled, (state, action) => {
            state.upcomingTask = action.payload;
            return;
        });
        builder.addCase(getLeadsStatistics.fulfilled, (state, action) => {
            state.leadStatistics = action.payload;
            return;
        });
        builder.addCase(getCustomerStatistics.fulfilled, (state, action) => {
            state.customerStatistics = action.payload;
            return;
        });
        builder.addCase(getContactStatistics.fulfilled, (state, action) => {
            state.contactStatistics = action.payload;
            return;
        });
        builder.addCase(getUpcomingMeetings.fulfilled, (state, action) => {
            state.upcomingMeetings = action.payload;
            return;
        });
        builder.addCase(getUpcomingCall.fulfilled, (state, action) => {
            state.upcomingCall = action.payload;
            return;
        });
        builder.addCase(getConversionRate.fulfilled, (state, action) => {
            state.conversionRate = action.payload;
            return;
        });
        builder.addCase(getLeadStages.fulfilled, (state, action) => {
            state.leadStages = action.payload;
            return;
        });
    }
});

export default dashboardSlice.reducer;
