//** department Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getDepartment = createAsyncThunk('departmentSlice/getDepartment', async () => {
    try {
        let response = await axios.get('/department', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getDepartmentOption = createAsyncThunk('departmentSlice/getDepartmentOption', async () => {
    try {
        let response = await axios.get('/department/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createDepartment = createAsyncThunk('departmentSlice/createDepartment', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/department', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Department added successfully.');
        await dispatch(getDepartment());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDepartment = createAsyncThunk('departmentSlice/updateDepartment', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/department/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Department updated successfully.');
        await dispatch(getDepartment());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateDepartmentStatus = createAsyncThunk('departmentSlice/updateLeadStatusStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/department/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Department Status updated successfully !');
        dispatch(getDepartment());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteDepartment = createAsyncThunk('departmentSlice/deleteDepartment', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/department/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Department deleted successfully.');
        await dispatch(getDepartment());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const departmentSlice = createSlice({
    name: 'departmentSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getDepartment.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getDepartmentOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default departmentSlice.reducer;
