// ** Reducers Imports
import navbar from './navbar';
import layout from './layout';
import auth from './authentication';
import users from '../views/Setup/User/store';
import roles from '../views/Setup/RoleRights/Role/store';
import country from '../views/Master/Location/Country/store/index';
import state from '../views/Master/Location/State/store';
import city from '../views/Master/Location/City/store';
import kanban from '../views/kanban/store/index';
import pipeline from '../views/kanban/store/index';
import list from '../views/List/store/index';
import leadSource from '../views/Master/LeadSource/store';
import lead from '../views/Lead/store';
import leadStatus from '../views/Master/LeadStatus/store';
import department from '../views/Master/Department/store';
import industry from '../views/Master/Industry/store';
import campaignType from '../views/Master/CampaignType/store';
import campaign from '../views/Master/Campaign/store';
import socials from '../views/Master/Socials/store';
import contact from '../views/Contact/store';
import account from '../views/Account/store/index';
import Tabs from './Tabs';
import leadProfile from '../views/LeadProfile/store';
import manufacturer from '../views/Master/Manufacturer/store/index';
import vendor from '../views/Master/Vendor/store/index';
import productCategory from '../views/Master/ProductCategory/store/index';
import tax from '../views/Master/Tax/store/index';
import unit from '../views/Master/Unit/store/index';
import product from '../views/Product/store/index';
import toDos from '../views/Todos/store';
import buyingRole from '../views/Master/BuyingRole/store/index';
import dealStage from '../views/Master/DealStage/store/index';
import deal from '../views/Deals/store/index';
import meeting from '../views/Meetings/store/index';
import calls from '../views/Calls/store/index';
import calendar from '../views/Calendar/store/index';
import outcome from '../views/Master/Outcome/store/index';
import customField from '../views/Settings/CustomField/store/index';
import emailTemplate from '../views/Settings/TemplateGallery/store/index';
import emailConfig from '../views/Settings/EmailConfig/store/index';
import automation from '../views/Settings/Automation/store/index';
import emailCategory from '../views/Master/EmailCategory/store/index';
import email from '../views/Settings/TemplateGallery/components/email/store';
import report from '../views/Report/store/index';
import oneOneZa from '../views/Settings/Configuration/store';
import notification from '../views/Settings/Notification/store';
import dashboard from '../views/Dashboard/store/index';
import customActivity from '../views/Settings/CustomActivityAndScores/store/index';
import meta from '../views/Settings/Integration/store/index';
import menuOrder from '../views/Settings/MenuOrder/store/index';
import apiIntegration from '../views/Settings/ApiIntegration/store/index';
import permission from './permission';

const rootReducer = {
    permission,
    Tabs,
    auth,
    navbar,
    layout,
    users,
    roles,
    kanban,
    pipeline,
    country,
    state,
    city,
    list,
    lead,
    leadSource,
    leadStatus,
    department,
    industry,
    campaignType,
    campaign,
    socials,
    contact,
    account,
    leadProfile,
    manufacturer,
    vendor,
    productCategory,
    tax,
    unit,
    product,
    toDos,
    buyingRole,
    dealStage,
    deal,
    meeting,
    calls,
    calendar,
    outcome,
    customField,
    emailTemplate,
    emailConfig,
    automation,
    emailCategory,
    email,
    report,
    oneOneZa,
    notification,
    dashboard,
    customActivity,
    meta,
    menuOrder,
    apiIntegration
};

export default rootReducer;
