//** lead-source Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getProductCategory = createAsyncThunk('productCategorySlice/getProductCategory', async () => {
    try {
        let response = await axios.get('/product-category', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getProductCategoryOption = createAsyncThunk('productCategorySlice/getProductCategoryOption', async () => {
    try {
        let response = await axios.get('/product-category/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createProductCategory = createAsyncThunk('productCategorySlice/createProductCategory', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/product-category', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Product Category added successfully.');
        dispatch(getProductCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateProductCategory = createAsyncThunk('productCategorySlice/updateProductCategory', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/product-category/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Product Category updated successfully.');
        await dispatch(getProductCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const updateProductCategoryStatus = createAsyncThunk(
    'productCategorySlice/updateProductCategoryStatus',
    async (id, { dispatch }) => {
        try {
            let response = await axios.put('/product-category/status/' + id, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Product Category Status updated successfully.');
            await dispatch(getProductCategory());
            return Promise.resolve(response);
        } catch (err) {
            toast.error(err?.response?.data?.message || 'Something went wrong!');
            console.log('err', err);
            return Promise.reject(err);
        }
    }
);

export const deleteProductCategory = createAsyncThunk('productCategorySlice/deleteProductCategory', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/product-category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Product Category deleted successfully.');
        await dispatch(getProductCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const productCategorySlice = createSlice({
    name: 'productCategorySlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getProductCategory.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getProductCategoryOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default productCategorySlice.reducer;
