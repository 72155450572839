//** lead-source Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getEmailConfiguration = createAsyncThunk('emailSlice/getEmailConfiguration', async () => {
    try {
        let response = await axios.get('/get-email-configuration', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});


export const emailConfig = createAsyncThunk('emailSlice/emailConfig', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/email-configuration', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Config Added successfully.');
        dispatch(getEmailConfiguration());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteEmailConfig = createAsyncThunk('emailSlice/deleteEmailConfig', async (id, { dispatch }) => {
    try {
        let response = await axios.delete('/email-configuration/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Lead Source Status updated successfully !');
        dispatch(getEmailConfiguration());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateEmailStatus = createAsyncThunk('emailSlice/updateEmailStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status Changed successfully.');
        await dispatch(getEmailConfiguration());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const emailSlice = createSlice({
    name: 'emailSlice',
    initialState: {
        data: []
        // option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getEmailConfiguration.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        // builder.addCase(getLeadSourceOption.fulfilled, (state, action) => {
        //     state.option = action.payload;
        //     return;
        // });
    }
});

export default emailSlice.reducer;
