//** lead-source Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getTax = createAsyncThunk('taxSlice/getTax', async () => {
    try {
        let response = await axios.get('/tax', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getTaxOption = createAsyncThunk('taxSlice/getTaxOption', async () => {
    try {
        let response = await axios.get('/tax/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createTax = createAsyncThunk('taxSlice/createTax', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/tax', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Tax added successfully.');
        dispatch(getTax());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateTax = createAsyncThunk('taxSlice/updateTax', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/tax/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Tax  updated successfully.');
        await dispatch(getTax());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateTaxStatus = createAsyncThunk('taxSlice/updateTaxStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/tax/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Tax Status updated successfully !');
        dispatch(getTax());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteTax = createAsyncThunk('taxSlice/deleteTax', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/tax/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Tax deleted successfully.');
        await dispatch(getTax());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const taxSlice = createSlice({
    name: 'taxSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getTax.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getTaxOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default taxSlice.reducer;
