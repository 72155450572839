var service = {
    mapLibraries: ['places']
};

if (process.env.NODE_ENV === 'production') {
    service.API_URL = process.env.REACT_APP_BACKEND_URL;
} else {
    service.API_URL = 'http://192.168.1.23:5002';
}

export default service;
