//** Lead Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';
import themeConfig from '../../../configs/themeConfig';
import showLoader from '@components/loader';

export const getLead = createAsyncThunk('leadSlice/getLead', async () => {
    try {
        let response = await axios.get('/lead', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getLeadById = createAsyncThunk('leadSlice/getLeadById', async (data) => {
    try {
        showLoader(true);
        let response = await axios.get('/lead/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        showLoader(false);
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLeadOption = createAsyncThunk('leadSlice/getLeadOption', async () => {
    try {
        let response = await axios.get('/lead/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getSalutationOption = createAsyncThunk('leadSlice/getSalutationOption', async () => {
    try {
        let response = await axios.get('/salutation/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createLead = createAsyncThunk('leadSlice/createLead', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/lead', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Lead added successfully.');
        // dispatch(getLeadByFilter());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateLead = createAsyncThunk('leadSlice/updateLead', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/lead/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Lead updated successfully.');
        // await dispatch(getLeadByFilter());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const getLeadTimeline = createAsyncThunk('leadSlice/getLeadTimeline', async (id, { dispatch }) => {
    try {
        let response = await axios.get('/lead-timeline/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateLeadStatus = createAsyncThunk('leadSlice/updateLeadStatus', async (id, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/lead/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Status updated successfully !');
        // dispatch(getLeadByFilter());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const deleteLead = createAsyncThunk('leadSlice/deleteLead', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/lead/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Lead deleted successfully.');
        // await dispatch(getLeadByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

//social options
export const getSocialsOption = createAsyncThunk('leadSlice/getSocialsOption', async () => {
    try {
        let response = await axios.get('/social/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);

        toast.error(err?.response?.data?.message || 'Something went wrong!');

        return Promise.reject(err);
    }
});

//convert lead
export const convertLead = createAsyncThunk('leadSlice/convertLead', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/convert-lead/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Convert Lead successfully.');
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

//for bulk update lead field
export const getLeadFieldOption = createAsyncThunk('leadSourceSlice/getLeadFieldOption', async () => {
    try {
        let response = await axios.get('/lead-field/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//lead field-filter options
export const getLeadFilterOption = createAsyncThunk('leadSourceSlice/getLeadFilterOption', async () => {
    try {
        let response = await axios.get('/lead-filter/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

//lead bulk update
export const leadBulkUpdate = createAsyncThunk('leadSlice/leadBulkUpdate', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/lead-bulk-update', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Bulk updated successfully !');
        // dispatch(getLeadByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const leadBulkImport = createAsyncThunk('leadSlice/leadBulkImport', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/lead/bulk-add', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Bulk Import successfully !');
        // dispatch(getLeadByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const leadBulkConvert = createAsyncThunk('leadSlice/leadBulkConvert', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/lead-bulk-convert', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Bulk Convert successfully !');
        // dispatch(getLeadByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

// bulk delete
export const deleteBulkLead = createAsyncThunk('leadSlice/deleteBulkLead', async (deleteIds, { dispatch }) => {
    try {
        let response = await axios.put('/lead-bulk-delete', deleteIds, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Lead deleted successfully.');
        // await dispatch(getLeadByFilter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getLeadByFilter = createAsyncThunk('leadSlice/getLeadByFilter', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/lead-filter', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getLeadByPipeline = createAsyncThunk('leadSlice/getLeadByPipeline', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/lead-pipeline-filter', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // toast.success(response?.data?.message || 'Lead added successfully.');
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updatePipelineTasks = createAsyncThunk('pipelineSliceData/updatePipelineTasks', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/change/lead-status', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // await dispatch(getPipeline());
        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getLeadCustomField = createAsyncThunk('leadSlice/getLeadCustomField', async () => {
    try {
        let response = await axios.get('/lead-custom-field', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const leadSlice = createSlice({
    name: 'leadSlice',
    initialState: {
        data: [],
        selectedLead: {},
        option: [],
        salutation: [],
        socialsOptions: [],
        leadFieldOptions: [],
        leadFilterOptions: [],
        leadFilter: [],
        leadPipeline: [],
        leadCustomField: []
    },
    reducers: {
        removeSelectedLead: (state, action) => {
            state.selectedLead = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getLead.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getLeadOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
        builder.addCase(getLeadById.fulfilled, (state, action) => {
            state.selectedLead = action.payload;
            return;
        });
        builder.addCase(getSalutationOption.fulfilled, (state, action) => {
            state.salutation = action.payload;
            return;
        });
        builder.addCase(getSocialsOption.fulfilled, (state, action) => {
            state.socialsOptions = action.payload;
            return;
        });
        builder.addCase(getLeadFieldOption.fulfilled, (state, action) => {
            state.leadFieldOptions = action.payload;
            return;
        });
        builder.addCase(getLeadFilterOption.fulfilled, (state, action) => {
            state.leadFilterOptions = action.payload;
            return;
        });
        builder.addCase(getLeadByFilter.fulfilled, (state, action) => {
            state.leadFilter = action.payload;
            return;
        });
        builder.addCase(getLeadByPipeline.fulfilled, (state, action) => {
            state.leadPipeline = action.payload;
            return;
        });
        builder.addCase(getLeadCustomField.fulfilled, (state, action) => {
            state.leadCustomField = action.payload;
            return;
        });
    }
});
export const { removeSelectedLead } = leadSlice.actions;

export default leadSlice.reducer;
