import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getEmailCategory = createAsyncThunk('emailSlice/getEmailCategory', async () => {
    try {
        let response = await axios.get('/email-template-category', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmailCategoryOption = createAsyncThunk('emailSlice/getEmailCategoryOption', async () => {
    try {
        let response = await axios.get('/email-template-category/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const createEmailCategory = createAsyncThunk('emailSlice/createEmailCategory', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/email-template-category', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Email Template Category added successfully.');
        dispatch(getEmailCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateEmailTemplate = createAsyncThunk('emailSlice/updateEmailTemplate', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/email-template-category/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Outcome updated successfully.');
        await dispatch(getEmailCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateEmailTemplateStatus = createAsyncThunk('emailSlice/updateEmailTemplateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/email-template-category/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getEmailCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteTemplateCategory = createAsyncThunk('emailSlice/deleteTemplateCategory', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/email-template-category/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Outcome deleted successfully.');
        await dispatch(getEmailCategory());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const emailSlice = createSlice({
    name: 'emailSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getEmailCategory.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getEmailCategoryOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default emailSlice.reducer;
