//** disposition-status Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';

export const getLeadStatus = createAsyncThunk('leadStatusSlice/getLeadStatus', async () => {
    try {
        let response = await axios.get('/disposition-status', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getLeadStatusOption = createAsyncThunk('leadStatusSlice/getLeadStatusOption', async () => {
    try {
        let response = await axios.get('/disposition-status/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createLeadStatus = createAsyncThunk('leadStatusSlice/createLeadStatus', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/disposition-status', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'disposition-status added successfully.');
        dispatch(getLeadStatus());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateLeadStatus = createAsyncThunk('leadStatusSlice/updateLeadStatus', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/disposition-status/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'disposition-status updated successfully.');
        await dispatch(getLeadStatus());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateLeadStatusStatus = createAsyncThunk('leadStatusSlice/updateLeadStatusStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/disposition-status/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || ' Status updated successfully !');
        dispatch(getLeadStatus());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteLeadStatus = createAsyncThunk('leadStatusSlice/deleteLeadStatus', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/disposition-status/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'disposition-status deleted successfully.');
        await dispatch(getLeadStatus());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const leadStatusSlice = createSlice({
    name: 'leadStatusSlice',
    initialState: {
        data: [],
        option: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLeadStatus.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getLeadStatusOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
    }
});

export default leadStatusSlice.reducer;
