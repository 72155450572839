//** Lead Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';
// import themeConfig from '../../../configs/themeConfig';

export const getApiIntegration = createAsyncThunk('apiIntegrationSlice/getApiIntegration', async (data) => {
    try {
        let response = await axios.get(`/api-key?type=${data}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateIntegration = createAsyncThunk('apiIntegrationSlice/updateIntegration', async (data, { dispatch }) => {
    try {
        let response = await axios.put('/api-key', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        dispatch(getApiIntegration());
        toast.success(response?.data?.message || 'API Key updated successfully.');
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const reGenerateToken = createAsyncThunk('apiIntegrationSlice/reGenerateToken', async (data, { dispatch }) => {
    try {
        let response = await axios.put(`/regenerate/auth-token?type=${data}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message);
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const apiIntegrationSlice = createSlice({
    name: 'apiIntegrationSlice',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getApiIntegration.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
    }
});

export default apiIntegrationSlice.reducer;
