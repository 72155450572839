//** buying-role Api

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Axios Imports
import { toast } from 'react-toastify';
import axios from '@src/service/axios';
import showLoader from '@components/loader';

export const getEmailTemplate = createAsyncThunk('emailTemplateSlice/getEmailTemplate', async () => {
    try {
        showLoader(true);
        let response = await axios.get('/email-templates', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        showLoader(false);
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmailTemplateById = createAsyncThunk('emailTemplateSlice/getEmailTemplateById', async (id) => {
    try {
        let response = await axios.get('/email-templates/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmailTemplateByCategory = createAsyncThunk('emailTemplateSlice/getEmailTemplateByCategory', async (id) => {
    try {
        let response = await axios.get(`/email-templates?categoryId=${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmailTemplateOption = createAsyncThunk('emailTemplateSlice/getEmailTemplateOption', async () => {
    try {
        let response = await axios.get('/email-templates/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getEmailTemplateCategoryOption = createAsyncThunk('emailTemplateSlice/getEmailTemplateCategoryOption', async () => {
    try {
        let response = await axios.get('/email-template-category/options', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response?.data?.data);
    } catch (err) {
        console.log('err', err);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createEmailTemplate = createAsyncThunk('emailTemplateSlice/createEmailTemplate', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.post('/email-templates', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Email Template Created successfully.');
        // dispatch(getEmailTemplate());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message?.msg || response?.data?.fields[0].msg || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateEmailTemplate = createAsyncThunk('emailTemplateSlice/updateEmailTemplate', async (data, { dispatch }) => {
    try {
        showLoader(true);
        let response = await axios.put('/email-templates/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        showLoader(false);
        toast.success(response?.data?.message || 'Email Template updated successfully.');
        await dispatch(getEmailTemplate());
        return Promise.resolve(response);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const updateEmailTemplateStatus = createAsyncThunk('emailTemplateSlice/updateEmailTemplateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/email-templates/status/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Status updated successfully !');
        dispatch(getEmailTemplate());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const deleteEmailTemplate = createAsyncThunk('emailTemplateSlice/deleteEmailTemplate', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/email-templates/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Email Template deleted successfully.');
        await dispatch(getEmailTemplate());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const emailTemplateSlice = createSlice({
    name: 'emailTemplateSlice',
    initialState: {
        data: [],
        option: [],
        categoryOption: [],
        selectedData: {},
        selectedCategory: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getEmailTemplate.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getEmailTemplateOption.fulfilled, (state, action) => {
            state.option = action.payload;
            return;
        });
        builder.addCase(getEmailTemplateCategoryOption.fulfilled, (state, action) => {
            state.categoryOption = action.payload;
            return;
        });
        builder.addCase(getEmailTemplateById.fulfilled, (state, action) => {
            state.selectedData = action.payload;
            return;
        });
        builder.addCase(getEmailTemplateByCategory.fulfilled, (state, action) => {
            state.selectedCategory = action.payload;
            return;
        });
    }
});

export default emailTemplateSlice.reducer;
